import { createApp } from 'vue';
import CKEditor from '@ckeditor/ckeditor5-vue';
import * as ElementPlusIconsVue from '@element-plus/icons-vue';
import App from './App.vue';
import router from './router';
import { store } from './store/index';
import './router/route-guard';
import ElementUI from 'element-plus';
import i18n from '@/i18n/i18n';
import 'element-plus/dist/index.css';
import '@/assets/style/blue/index.less';
import VueMarkdownEditor from '@kangc/v-md-editor';
import '@kangc/v-md-editor/lib/style/base-editor.css';
import vuepressTheme from '@kangc/v-md-editor/lib/theme/vuepress.js';
import '@kangc/v-md-editor/lib/theme/style/vuepress.css';
import Prism from 'prismjs';
VueMarkdownEditor.use(vuepressTheme, {
    Prism,
});
import VMdPreview from '@kangc/v-md-editor/lib/preview';
import '@kangc/v-md-editor/lib/style/preview.css';
import githubTheme from '@kangc/v-md-editor/lib/theme/github.js';
import '@kangc/v-md-editor/lib/theme/style/github.css';
// highlightjs
import hljs from 'highlight.js';
VMdPreview.use(githubTheme, {
    Hljs: hljs,
});
const app = createApp(App);
for (const [key, component] of Object.entries(ElementPlusIconsVue)) {
    app.component(key, component);
}
/* 定义全局变量 */
app.config.globalProperties.$isCollapse = false;
app.config.globalProperties.$overall = {
    isCollapse: false,
    wsMessage: []
};
app.directive('preventReClick', {
    mounted(el, binding) {
        el.addEventListener('click', () => {
            if (el.style.pointerEvents != 'none') {
                el.style.pointerEvents = 'none';
                const timeout = binding.value || 1000;
                setTimeout(() => {
                    el.style.pointerEvents = 'all';
                }, timeout);
            }
        });
    }
});
app.config.globalProperties.$t = (key, value) => i18n.global.t(key, value);
const setupApp = () => {
    app.use(store);
    app.use(router);
    app.use(ElementUI);
    app.use(CKEditor);
    app.use(VMdPreview);
    app.use(VueMarkdownEditor);
    app.mount('#app');
};
setupApp();
