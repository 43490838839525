import { createRouter, createWebHistory } from 'vue-router';
const Admin = () => import('@/views/admin/LoginView.vue');
const SendRedirect = () => import('@/views/admin/SendRedirect.vue');
const NotFound = () => import('@/views/error/404.vue');
const PageView = () => import('@/views/page/Index.vue');
const ChatGpt = () => import('@/views/page/chat/Index.vue');
const Article = () => import('@/views/page/article/Index.vue');
const Tool = () => import('@/views/page/tool/Index.vue');
const Developer = () => import('@/views/page/tool/developer/Index.vue');
const iJson = () => import('@/views/page/tool/developer/json/Index.vue');
const IdCard = () => import('@/views/page/tool/developer/idcard/Index.vue');
const Uuid = () => import('@/views/page/tool/developer/uuid/Index.vue');
const About = () => import('@/views/page/about/Index.vue');
const Password = () => import('@/views/page/tool/developer/password/Index.vue');
const Timestamp = () => import('@/views/page/tool/developer/timestamp/Index.vue');
const MainPage = () => import('@/views/page/article/main/Index.vue');
const Details = () => import('@/views/page/article/details/Index.vue');
const Type = () => import('@/views/page/article/type/Index.vue');
const Search = () => import('@/views/page/article/list/Index.vue');
const wiki = () => import('@/views/page/open/Wiki.vue');
const wikiIndex = () => import('@/views/page/open/wiki/Index.vue');
const routes = [
    {
        path: '/admin/login',
        name: 'Login',
        meta: {
            title: '登录',
            local: 'login.login'
        },
        component: Admin
    },
    {
        path: '/wechat/login',
        name: 'wechatLogin',
        meta: {
            title: '登录跳转',
            local: 'login.login'
        },
        component: SendRedirect
    },
    {
        path: '/404',
        name: 'NotFound',
        meta: {
            title: '404',
            local: 'login.login'
        },
        component: NotFound
    },
    {
        path: '',
        name: 'PageView',
        meta: {
            title: 'CdkjFw 维基框架,基于Spring Boot,Spring Cloud 的微服务快速开发平台',
            local: 'page.title'
        },
        component: PageView
    },
    {
        path: '/index',
        name: 'PageView-Index',
        meta: {
            title: 'CdkjFw 维基框架,基于Spring Boot,Spring Cloud 的微服务快速开发平台',
            local: 'page.title'
        },
        component: PageView
    },
    {
        path: '/about',
        name: 'PageView-About',
        meta: {
            title: 'CdkjFw 维基框架,基于Spring Boot,Spring Cloud 的微服务快速开发平台',
            local: 'page.about'
        },
        component: About
    },
    {
        path: '/chat/gpt',
        name: 'ChatGpt',
        meta: {
            title: 'CdkjFw 维基框架,chat,gpt,chatgpt',
            local: 'page.gpt'
        },
        component: ChatGpt,
    },
    {
        path: '/article',
        name: 'Article',
        meta: {
            title: 'CdkjFw 维基框架,Spring Boot,Spring Cloud ,java,mysql,sql,C#,学习资料',
            local: 'page.article'
        },
        component: Article,
        children: [
            {
                path: '/article',
                name: 'Article',
                meta: {
                    title: 'CdkjFw 维基框架,基于Spring Boot,Spring Cloud 的微服务快速开发平台',
                    local: 'page.article'
                },
                component: MainPage,
            },
            {
                path: 'details/:id',
                name: 'Details',
                meta: {
                    title: 'CdkjFw 维基框架,基于Spring Boot,Spring Cloud 的微服务快速开发平台',
                    local: 'page.articleDetails'
                },
                component: Details,
            },
            {
                path: 'type/:constCode',
                name: 'Type',
                meta: {
                    title: 'CdkjFw 维基框架,基于Spring Boot,Spring Cloud 的微服务快速开发平台',
                    local: 'page.articleType'
                },
                component: Type,
            },
            {
                path: 'list/:search',
                name: 'list',
                meta: {
                    title: 'CdkjFw 维基框架,基于Spring Boot,Spring Cloud 的微服务快速开发平台',
                    local: 'page.search'
                },
                component: Search,
            }
        ]
    },
    {
        path: '/wiki',
        name: 'wiki',
        meta: {
            title: 'cdkjFw 开发框架基础公共包，方便快捷分模块引用',
            local: 'page.wiki'
        },
        component: wiki,
        children: [
            {
                path: '/wiki',
                name: 'wikiIndex',
                meta: {
                    title: 'cdkjFw 开发框架基础公共包，方便快捷分模块引用',
                    local: 'wiki.index'
                },
                component: wikiIndex
            },
            {
                path: '/wiki-framework',
                name: 'wikiFramework',
                meta: {
                    title: 'cdkjFw 开发框架基础公共包，方便快捷分模块引用',
                    local: 'wiki.framework'
                },
                component: () => import('@/views/page/open/wiki/framework/children/Intro.vue')
            },
            {
                path: '/wiki-proxy',
                name: 'wikiProxy',
                meta: {
                    title: '维基代理（wiki-proxy）、开源的java内网穿透项目',
                    local: 'wiki.proxy'
                },
                component: () => import('@/views/page/open/wiki/proxy/children/Intro.vue')
            },
        ]
    },
    {
        path: '/wiki-proxy/intro',
        name: 'proxyIntro',
        meta: {
            title: 'cdkjFw 开发框架基础公共包，方便快捷分模块引用',
            local: 'wikiProxy.intro'
        },
        component: () => import('@/views/page/open/wiki/proxy/children/Intro.vue')
    },
    {
        path: '/wiki-proxy/jar-deploy',
        name: 'JarDeploy',
        meta: {
            title: 'cdkjFw 开发框架基础公共包，方便快捷分模块引用',
            local: 'wikiProxy.quickStart'
        },
        component: () => import('@/views/page/open/wiki/proxy/children/Jar-Deploy.vue')
    },
    {
        path: '/wiki-proxy/docker-deploy',
        name: 'DockerDeploy',
        meta: {
            title: 'cdkjFw 开发框架基础公共包，方便快捷分模块引用',
            local: 'wikiProxy.dockerDeploy'
        },
        component: () => import('@/views/page/open/wiki/proxy/children/Docker-Deploy.vue')
    },
    {
        path: '/wiki-proxy/backend-operation-guide',
        name: 'BackendOperationGuide',
        meta: {
            title: 'cdkjFw 开发框架基础公共包，方便快捷分模块引用',
            local: 'wikiProxy.backendOperationGuide'
        },
        component: () => import('@/views/page/open/wiki/proxy/children/Backend-Operation-Guide.vue')
    },
    {
        path: '/wiki-proxy/about-ports',
        name: 'AboutPorts',
        meta: {
            title: 'cdkjFw 开发框架基础公共包，方便快捷分模块引用',
            local: 'wikiProxy.aboutPorts'
        },
        component: () => import('@/views/page/open/wiki/proxy/children/About-Ports.vue')
    },
    {
        path: '/wiki-proxy/domain-mapping',
        name: 'DomainMapping',
        meta: {
            title: 'cdkjFw 开发框架基础公共包，方便快捷分模块引用',
            local: 'wikiProxy.domainMapping'
        },
        component: () => import('@/views/page/open/wiki/proxy/children/Domain-Mapping.vue')
    },
    {
        path: '/wiki-proxy/https-configure',
        name: 'HttpsConfigure',
        meta: {
            title: 'cdkjFw 开发框架基础公共包，方便快捷分模块引用',
            local: 'wikiProxy.httpsConfigure'
        },
        component: () => import('@/views/page/open/wiki/proxy/children/Https-Configure.vue')
    },
    {
        path: '/wiki-proxy/server-configure',
        name: 'ServerConfigure',
        meta: {
            title: 'cdkjFw 开发框架基础公共包，方便快捷分模块引用',
            local: 'wikiProxy.serverConfigure'
        },
        component: () => import('@/views/page/open/wiki/proxy/children/Server-Configure.vue')
    },
    {
        path: '/wiki-proxy/client-configure',
        name: 'ClientConfigure',
        meta: {
            title: 'cdkjFw 开发框架基础公共包，方便快捷分模块引用',
            local: 'wikiProxy.clientConfigure'
        },
        component: () => import('@/views/page/open/wiki/proxy/children/Client-Configure.vue')
    },
    {
        path: '/wiki-proxy/client-connection-server-failed',
        name: 'proxyClientConnectionServerFailed',
        meta: {
            title: 'cdkjFw 开发框架基础公共包，方便快捷分模块引用',
            local: 'wikiProxy.clientConnectionServerFailed'
        },
        component: () => import('@/views/page/open/wiki/proxy/children/Client-Connection-Server-Failed.vue')
    },
    {
        path: '/wiki-framework/intro',
        name: 'frameworkIntro',
        meta: {
            title: 'cdkjFw 开发框架基础公共包，方便快捷分模块引用',
            local: 'wikiFramework.intro'
        },
        component: () => import('@/views/page/open/wiki/framework/children/Intro.vue')
    },
    {
        path: '/wiki-framework/client-connection-server-failed',
        name: 'frameworkClientConnectionServerFailed',
        meta: {
            title: 'cdkjFw 开发框架基础公共包，方便快捷分模块引用',
            local: 'wikiFramework.clientConnectionServerFailed'
        },
        component: () => import('@/views/page/open/wiki/framework/children/Client-Connection-Server-Failed.vue')
    },
    {
        path: '/tool',
        name: 'ToolView',
        meta: {
            title: 'CdkjFw 维基框架,基于Spring Boot,Spring Cloud 的微服务快速开发平台',
            local: 'page.tool'
        },
        component: Tool,
        children: [
            {
                path: '/tool',
                name: 'ToolView',
                meta: {
                    title: 'CdkjFw 维基框架,基于Spring Boot,Spring Cloud 的微服务快速开发平台',
                    local: 'page.tool'
                },
                component: Developer
            },
            {
                path: 'developer/timestamp',
                name: 'timestamp',
                meta: {
                    title: 'CdkjFw 维基框架,基于Spring Boot,Spring Cloud 的微服务快速开发平台',
                    local: 'page.timestamp'
                },
                component: Timestamp
            },
            {
                path: 'developer/password',
                name: 'password',
                meta: {
                    title: 'CdkjFw 维基框架,基于Spring Boot,Spring Cloud 的微服务快速开发平台',
                    local: 'page.password'
                },
                component: Password,
            },
            {
                path: 'developer/json',
                name: 'iJson',
                meta: {
                    title: 'CdkjFw 维基框架,基于Spring Boot,Spring Cloud 的微服务快速开发平台,在线随机身份证号码生成工具,支持在线批量生成随机身份证号码,生成的信息中包含省市区县,出生日期,性别,年龄',
                    local: 'page.json'
                },
                component: iJson,
            },
            {
                path: 'developer/idcard',
                name: 'idcard',
                meta: {
                    title: 'CdkjFw 维基框架,基于Spring Boot,Spring Cloud 的微服务快速开发平台,在线随机身份证号码生成工具,支持在线批量生成随机身份证号码,生成的信息中包含省市区县,出生日期,性别,年龄',
                    local: 'page.idcard'
                },
                component: IdCard,
            },
            {
                path: 'developer/uuid',
                name: 'uuid',
                meta: {
                    title: 'CdkjFw 维基框架,基于Spring Boot,Spring Cloud 的微服务快速开发平台,在线随机身份证号码生成工具,支持在线批量生成随机身份证号码,生成的信息中包含省市区县,出生日期,性别,年龄',
                    local: 'page.uuid'
                },
                component: Uuid,
            }
        ]
    },
];
const router = createRouter({
    history: createWebHistory(),
    // history: createWebHashHistory(),
    // mode: 'hash',
    routes
});
export default router;
