import { METHODS, request } from '@/utils/request';
export function userLoginApi(data) {
    let params = '';
    for (const key in data) {
        if (params != '') {
            params += '&';
        }
        params += key + '=' + data[key];
    }
    return request({
        baseURL: '/single/security/user/login',
        method: METHODS.POST,
        data: params,
    }, {
        isDataDirectly: false
    });
}
export function userTicketLoginApi(data) {
    return request({
        baseURL: '/portal/user/login?ticket=' + data,
        method: METHODS.POST,
        data: data
    }, {
        isDataDirectly: false
    });
}
export function logoutApi(data = {}) {
    return request({
        baseURL: '/single/security/user/logout',
        method: METHODS.POST,
        data
    }, {
        isDataDirectly: false
    });
}
export function listRmsUserPageApi(data = {}) {
    return request({
        baseURL: '/single/rms/user/listRmsUserPage',
        method: METHODS.POST,
        data
    }, {
        isDataDirectly: false
    });
}
export function insertUserApi(data = {}) {
    return request({
        baseURL: '/single/rms/user/addRmsUser',
        method: METHODS.POST,
        data
    }, {
        isDataDirectly: false
    });
}
export function modifyUserApi(data = {}) {
    return request({
        baseURL: '/single/rms/user/modifyRmsUser',
        method: METHODS.POST,
        data
    }, {
        isDataDirectly: false
    });
}
export function resetApi(data = {}) {
    return request({
        baseURL: '/single/rms/user/reset?id=' + data,
        method: METHODS.POST
    }, {
        isDataDirectly: false
    });
}
export function buildLoginSateApi(data = {}) {
    return request({
        baseURL: '/single/wechat/buildLoginSate',
        method: METHODS.POST
    }, {
        isDataDirectly: false
    });
}
export function findRmsUserChatApi(data = {}) {
    return request({
        baseURL: '/portal/rms/user/chat/findRmsUserChat',
        method: METHODS.POST,
        data
    }, {
        isDataDirectly: false
    });
}
