const tw = {
    title: 'CdkjFw 维基框架,基于Spring Boot,Spring Cloud 的微服务快速开发平台',
    login: {
        systemName: 'CdkjFw 后台管理中心',
        username: '输入邮箱地址',
        password: '输入密码',
        code: '输入验证码',
        login: '登录',
        register: '注册用户',
        forgot: '忘记密码'
    },
    page: {
        title: '',
        index: '首页',
        timestamp: '时间戳转换',
        password: '生成随机密码',
        json: 'JSON格式化',
        idcard: '生成身份证号',
        uuid: '生成uuid',
        tool: '工具',
        article: '文章',
        articleDetails: '文章详情',
        articleType: '文章列表',
        search: '搜索列表'
    },
    wiki: {
        index: 'cdkjFw 开发框架基础公共包，方便快捷分模块引用',
        framework: 'cdkjFw 开发框架基础公共包，方便快捷分模块引用',
        proxy: '维基代理（wiki-proxy）、开源的java内网穿透项目',
    },
    wikiProxy: {
        intro: '维基代理（wiki-proxy）、开源的java内网穿透项目',
        quickStart: '维基代理（wiki-proxy）、开源的java内网穿透项目',
        dockerDeploy: '维基代理（wiki-proxy）、开源的java内网穿透项目',
        backendOperationGuide: '维基代理（wiki-proxy）、开源的java内网穿透项目',
        aboutPorts: '维基代理（wiki-proxy）、开源的java内网穿透项目',
        domainMapping: '维基代理（wiki-proxy）、开源的java内网穿透项目',
        httpsConfigure: '维基代理（wiki-proxy）、开源的java内网穿透项目',
        serverConfigure: '维基代理（wiki-proxy）、开源的java内网穿透项目',
        clientConfigure: '维基代理（wiki-proxy）、开源的java内网穿透项目',
        clientConnectionServerFailed: '维基代理（wiki-proxy）、开源的java内网穿透项目'
    },
    wikiFramework: {
        intro: '维基代理（wiki-proxy）、开源的java内网穿透项目',
        quickStart: '维基代理（wiki-proxy）、开源的java内网穿透项目',
        dockerDeploy: '维基代理（wiki-proxy）、开源的java内网穿透项目',
        backendOperationGuide: '维基代理（wiki-proxy）、开源的java内网穿透项目',
        aboutPorts: '维基代理（wiki-proxy）、开源的java内网穿透项目',
        domainMapping: '维基代理（wiki-proxy）、开源的java内网穿透项目',
        httpsConfigure: '维基代理（wiki-proxy）、开源的java内网穿透项目',
        serverConfigure: '维基代理（wiki-proxy）、开源的java内网穿透项目',
        clientConfigure: '维基代理（wiki-proxy）、开源的java内网穿透项目',
        clientConnectionServerFailed: '维基代理（wiki-proxy）、开源的java内网穿透项目'
    }
};
export default tw;
